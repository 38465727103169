import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule, AuthConfig } from '@auth0/auth0-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, ErrorHandlerService } from './core';
import { LaunchDarklyService } from './core/launch-darkly/launchdarkly.service';
import { UserProfileResolver } from './core/profile/shared/user-profile.resolver';
import { AppShellModule } from './features/app-shell/app-shell.module';
import { ToastMessageModule } from './shared/components/toast-message';
import { TemplateTagsResolver } from './shared/resolvers/template-tags.resolver';
import { SharedModule } from './shared/shared.module';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    AppShellModule,
    NgbModule,
    FontAwesomeModule,
    ToastMessageModule,
    AuthModule.forRoot({ ...environment.auth0 } as AuthConfig),
  ],
  providers: [
    LaunchDarklyService,
    UserProfileResolver,
    TemplateTagsResolver,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(launchDarklyService: LaunchDarklyService) {
    launchDarklyService.init();
  }
}
